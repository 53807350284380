import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { eventsRef, database } from '../../database'
import { storage } from '../../firestore'
import { onSnapshot, addDoc, deleteDoc, doc } from 'firebase/firestore'
import { deleteObject, ref, uploadBytes } from 'firebase/storage'

import {
  updateEvents,
  removeEvents,
  addNotification,
  eventsHideLoading
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants, firestoreConstants } from '../../constants'
import { firestoreHelper } from '../../helpers'

export let eventsSnap: any

export function* loadEventsSaga(): Generator<any, any, any> {
  try {
    const listener = eventChannel((emit) => {
      eventsSnap = onSnapshot(eventsRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => eventsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateEvents({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateEvents({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeEvents({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* addEventsSaga(action: any): Generator<any, any, any> {
  try {
    const buffer = action.payload.buffer
    delete action.payload.buffer

    const storageRef = ref(
      storage,
      firestoreConstants.events + '/' + action.payload.image
    )
    yield uploadBytes(
      storageRef,
      buffer,
      firestoreHelper.metadata(action.payload.image)
    )
    yield addDoc(eventsRef, action.payload)
    yield put(
      addNotification(
        uiConstants.messages.events_add_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(eventsHideLoading())
  }
}

export function* removeEventsSaga(action: any): Generator<any, any, any> {
  try {
    const storageRef = ref(
      storage,
      firestoreConstants.events + '/' + action.payload.image
    )

    yield deleteObject(storageRef)

    yield deleteDoc(doc(database, firestoreConstants.events, action.payload.id))
    yield put(
      addNotification(
        uiConstants.messages.events_remove_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(eventsHideLoading())
  }
}
